<template>
  <div>
    <alp-menu
      :items="menuItems"
      :desktop-logo="hotel.logo.desktop"
      :mobile-logo="hotel.logo.mobile"
    ></alp-menu>

        <video-background src="/videos/spannort4.mp4" class="video-home-page mb-md-5">

    <alp-welcome
      class=""
      :socials="socials"
    >
      <template v-slot:main>
        <b-container>
          <b-row
            ><b-col cols="12" md="9" xl="7">
              <div class="welcome-gold-text text-white p-4 mb-2 mb-md-5">
                <b-row align-v="center">
                  <b-col class="d-none d-md-block"></b-col>
                  <b-col><hr style="opacity: 0.5" /></b-col>
                  <b-col cols="auto">
                    <h2
                      class="m-0 p-0 alp-bodoni font-weight-bold"
                      style="font-size: 2.5rem"
                      
                    >
                     {{$t("WELCOME")}}
                    </h2></b-col
                  >
                  <b-col><hr style="opacity: 0.5" /></b-col>
                  <b-col class="d-none d-md-block"></b-col>
                </b-row>
                <p style="font-size: 1rem;" v-html="hotel.desc"></p>
                <div class="text-center">
                  <img :src="hotel.signatureImage" />
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </alp-welcome>
</video-background>
<b-container class="d-md-block">
      <b-row align-v="center" class="mt-4 justify-content-center">
        <b-col class="pr-md-5 col-md-8">
          <p style="font-size: 2rem" class="mb-3 d-md-block alp-bodoni">
            {{ this.$t("introTitle") }}
          </p>
          <p class="text-alp-muted" v-html="this.$t('introText')"></p>
        </b-col>

      </b-row>

    </b-container>

    <b-container class="d-md-block">
      <b-row align-v="center" class="d-md-none mb-5">
      <!--<b-col class="text-center">
          <!-/- <img class="rounded-alp" src="/img/video.png" style="width: 100%" /> -/->
          <video style="width: 100%; height: auto" controls>
            <source src="/videos/spannort.mp4" type="video/mp4" />
          </video>
        </b-col> -->
        </b-row>
        <b-row align-h="center" class="mb-5">
        <div class="col-12 col-md-4 col-xs-6">
            <a class="btn w-100 btn-dark btn-lg" style="min-width: 100px; line-height: 3" href="https://mytools.aleno.me/reservations/v2.0/reservations.html?k=eyJrIjoid2l2dTVrM2lsNm15cnBiOWlwdzZ4bmViajhycnVkaWRpZ280bGZwODBsbzlhNGlweTEiLCJyIjoiaGZkYkwzNHpwZ2pHUmk4REIiLCJzIjoiaHR0cHM6Ly9teXRvb2xzLmFsZW5vLm1lLyJ9">
              {{this.$t("Book your table here")}}
            </a>
        </div>
        </b-row>
    </b-container>

    <div
      v-for="perk in hotel.perks"
      :id="perk.title"
      class="pt-5"
      :key="'perk-' + perk.title"
    >
      <b-container>
        <b-row class="my-5">
          <b-col sm="12" md="10" offset-md="1" class="text-center">
            <div style="font-size: 1.5rem" class="my-4 alp-bodoni">
              {{ perk.title }}
            </div>
            <p
              class="text-alp-muted"
              v-for="(parg,index) in perk.paragraphs"
              :key="index"
              v-html="parg"
            ></p>
          </b-col>
        </b-row>
      </b-container>
      <img :src="perk.image" class="w-100" />
    </div>
    <!-- mobile apartments -->
    
    <alp-home/>

    <alp-footer></alp-footer>
  </div>
</template>

<script>
//import { gmapsMap, gmapsMarker } from "x5-gmaps";

export default {
  //components: { gmapsMap, gmapsMarker },
  data() {
    return {
      activity: null,
      activityPreview: null,
      options: {
        type: "slide",
        focus: "center",
        trimSpace: false,
        fixedWidth: "90%",
        arrows: false,
      },
      optionsMobileApartments: {
        type: "slide",
        focus: "center",
        trimSpace: true,
        fixedWidth: "60%",
        arrows: false,
      },
      hotels: [
        {
          socials: {
            facebook: "https://www.facebook.com/Spannort/",
            instagram: "https://www.instagram.com/restaurantspannort/",
            video: "https://www.engelberg.ch/en/webcams/",
            tram: "https://www.engelberg.ch/en/open-trails/",
            thermo: "https://www.engelberg.ch/en/weather/",
          },
          linkToBook: "",
          selectionType: "Room",
          url: "spannort-restaurant",
          name: "Spannort Restaurant",
          desc: this.$t('spannortWelcome'),
          location: "Address Goes Here 001",
          locationImg: "/img/maps/map.png",
          gmap: {
              lat: 0,
              lng: 0
          },
          variant: "alp-red",
          logo: {
            desktop: "/img/logo/logo-spannort.png",
            mobile: "/img/logo/mobile/logo-spannort.png?v2",
          },
          signatureImage: "/img/signatures/spannort.png",
        }
      ],
    };
  },
  computed: {
    menuItems() {
      var data = [];

      data.push({
        name: this.$t("Home"),
        url: "#",
      });

      if (this.hotel && this.hotel.perks) {
        this.hotel.perks.forEach((perk) => {
          data.push({
            name: perk.title,
            url: "#" + perk.title,
          });
        });
      }
      data.push({
        name: this.$t("About Us"),
        url: "#location-our-team",
      });
      // data.push({
      //   name: "Food/Drink",
      //   url: "#food-drink",
      // });
      data.push({
        name: this.$t("Food/Drink"),
        url: "#fooddrink",
      });
    //   data.push({
    //     name: this.$t("Products"),
    //     url: "#products",
    //   });
      data.push({
        name: this.$t("Contact"),
        url: "#location-contact-us",
      });
      return data;
    },
    welcomeBackground() {
      return this.$root.season == "winter"
          ? "bg-welcome-booking-alp-winter"
          : "bg-welcome-booking-alp-winter";
    },
    hotel() {
      const hotel = this.$route.params.hotel != null ? this.hotels.find((x) => x.url == this.$route.params.hotel) : this.hotels[0];
      console.log(this.$route.params.hotel);
      return hotel;
    },
    apartment() {
      const apartment = this.$route.params.apartment != null ? this.hotel.apartments.find(
        (x) => x.url == this.$route.params.apartment
      )
      : this.hotel.apartments[0];
      return apartment;
    },
    socials() {
      if (!this.hotel) {
        return [];
      }

      return [
        { icon: "fab fa-facebook", link: this.hotel.socials.facebook },
        { icon: "fab fa-instagram", link: this.hotel.socials.instagram },
        { icon: "fas fa-video", link: this.hotel.socials.video },
        { icon: "fas fa-tram", link: this.hotel.socials.tram },
        { icon: "fas fa-thermometer-half", link: this.hotel.socials.thermo },
      ];
    },
  },
  methods: {
    openLocation(link) {
      window.open(link, "_blank");
    },
    setActivity(activity) {
      this.activity = activity;
    },
    setActivityPreview(content) {
      this.activityPreview = content;
    },
    goBackActivity() {
      if (this.activityPreview) {
        this.activityPreview = null;
        return;
      }

      if (this.activity) {
        this.activity = null;
        this.activityPreview = null;
        return;
      }
    },
    bookNow() {
      if (!this.hotel.linkToBook) {
        alert("Not available at the moment.");
      } else {
        window.open(this.hotel.linkToBook);
      }
    },
    changeHotelAndApartment(hotel, apartment) {
      const url = "/booking/" + hotel.url + "/" + apartment.url;

      if (this.$route.path != url) {
        this.$router.push(url);
      }
    },
  },
  mounted() {
    window.runCommon();
  },
};
</script>

