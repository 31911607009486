<template>
    <div>
        <div :class="'bg-welcome-base ' + customClass + ' h-100'">
            <slot name="main"> </slot>

            <!-- Desktop -->
            <div
                v-if="socials.length > 0"
                class="text-shadow d-none d-md-flex flex-column py-4 position-absolute text-center text-white"
                :style="'top:' + socialTop + '%;right:5%;'"
            >
                <a
                    v-for="social in socials"
                    :key="'soc1-' + social"
                    class="text-white"
                    target="_blank"
                    :href="social.link"
                >
                    <i style="font-size: 1.2rem" :key="'social1-' + social.icon" :class="'mb-5 ' + social.icon"></i>
                </a>
            </div>

            <!-- Mobile -->
            <div v-if="socials.length > 0" class="text-shadow d-md-none py-4 text-center text-white">
                <a
                    v-for="social in socials"
                    :key="'soc2-' + social"
                    class="text-white"
                    target="_blank"
                    :href="social.link"
                >
                    <i
                        style="font-size: 1.2rem"
                        :key="'social2-' + social.icon"
                        :class="'mr-3 ml-3 ' + social.icon"
                    ></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["customClass", "socials"],
    data() {
        return {};
    },
    computed: {
        socialTop() {
            if (this.$route.name == "booking") {
                return "20";
            }
            return "25";
        },
    },
    methods: {},
};
</script>

