<template>
    <b-container fluid class="fixed-top text-shadow alp-navbar">
        <!-- Mobile Menu -->
        <b-container class="d-md-none">
            <b-row>
                <b-col cols="12" class="pt-3 d-flex flex-row align-items-center">
                    <img :src="currentMobileLogo" />
                    <div @click.prevent="$root.swLang()" class="cursor-pointer ml-auto text-white lang-icons">
                        <div v-if="$root.lang == 'EN'">[EN] &nbsp;&nbsp;|&nbsp;&nbsp; DE</div>
                        <div v-else>EN &nbsp;&nbsp;|&nbsp;&nbsp; [DE]</div>
                    </div>
                    <img class="ml-3" @click="$root.swSeason()" :src="seasonIcon" />
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <ul class="navbar-nav d-flex flex-row flex-wrap text-uppercase">
                        <li v-for="item in items" :key="'mmm' + item.name + item.url" class="nav-item mr-3">
                            <a v-if="item.title == 'Home'" class="nav-link" @click="goToLink(item.url)">{{
                                item.name
                            }}</a>

                            <a v-else class="nav-link py-2" :href="item.url">{{ item.name }}</a>
                        </li>
                    </ul>
                </b-col>
            </b-row>
        </b-container>

        <!-- Desktop Menu -->
        <b-container class="d-none d-md-block">
            <b-row align-v="center" :class="'row-menu row-menu-' + ($route.name == 'home' ? 'booking' : 'home')">
                <b-col cols="auto">
                    <img class="menu-logo-specific" :src="currentDesktopLogo" />
                    <img class="menu-logo-general" style="display: none" src="/img/logo/logo.png" />
                </b-col>
                <b-col class="d-flex flex-row text-uppercase alp-menu-items">
                    <ul class="navbar-nav d-flex flex-row w-100">
                        <li v-for="item in items" :key="'mmd' + item.name + item.url" class="nav-item mr-5">
                            <a
                                v-if="item.title == 'Home'"
                                style="cursor: pointer"
                                class="nav-link py-2"
                                @click="goToLink(item.url)"
                                >{{ item.name }}</a
                            >

                            <a v-else style="cursor: pointer" class="nav-link py-2" :href="item.url">{{ item.name }}</a>
                        </li>

                        <li class="nav-item ml-auto mr-4" style="cursor: pointer">
                            <img @click="$root.swSeason()" :src="seasonIcon" />
                        </li>

                        <li class="nav-item">
                            <a style="cursor: pointer" class="nav-link py-2" @click="$root.swLang()">
                                <span v-if="$root.lang == 'EN'">[EN] &nbsp;&nbsp;|&nbsp;&nbsp; DE</span>
                                <span v-else>EN &nbsp;&nbsp;|&nbsp;&nbsp; [DE]</span>
                            </a>
                        </li>
                    </ul>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
export default {
    props: ["items", "desktopLogo", "mobileLogo"],
    computed: {
        seasonIcon() {
            if (this.$root.season == "winter") {
                return "/img/toggle-winter.svg";
            }

            return "/img/toggle-summer.svg";
        },
        currentDesktopLogo() {
            if (!this.desktopLogo) {
                return "/img/logo/logo.png";
            }

            return this.desktopLogo;
        },
        currentMobileLogo() {
            if (!this.mobileLogo) {
                return "/img/logo/mobile/logo.png";
            }

            return this.mobileLogo;
        },
    },
    methods: {
        goToLink(url) {
            if (this.$route.path != url) {
                this.$router.push(url);
            }
        },
    },
};
</script>

