
import $ from 'jquery'
window.$ = $;
window._ = require('lodash');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.runCommon = function () {
    function alpOnScroll() {
        if ($(window).scrollTop() > 50) {
            $(".alp-navbar").addClass("bg-white shadow-alp");
            $(".alp-navbar").removeClass("text-shadow");

            $(".row-menu-home").removeClass("py-4");
            $(".row-menu").addClass("py-2");
            $(".row-menu-booking").find('.navbar-nav').removeClass("mb-5");

            $(".alp-navbar").find('.nav-link').addClass('text-dark');
            $(".alp-navbar").find('.nav-link').removeClass('text-white');

            $(".lang-icons").addClass('text-dark');

            $(".menu-logo-specific").hide();
            $(".menu-logo-general").show();
        }
        else {
            $(".alp-navbar").removeClass("bg-white shadow-alp");
            $(".alp-navbar").addClass("text-shadow");

            $(".row-menu-home").addClass("py-4");
            $(".row-menu").removeClass("py-2");
            $(".row-menu-booking").find('.navbar-nav').addClass("mb-5");

            $(".alp-navbar").find('.nav-link').removeClass('text-dark');
            $(".alp-navbar").find('.nav-link').addClass('text-white');


            $(".lang-icons").removeClass('text-dark');

            $(".menu-logo-specific").show();
            $(".menu-logo-general").hide();
        }
    }

    $(window).scroll(function () {
        alpOnScroll();
    });

    alpOnScroll();

    $('.home-area').mouseenter(function () {
        $(this).find('.home-area-absolute').fadeIn(150);
    }).mouseleave(function () {
        $('.home-area-absolute').hide();
    });

    window.scrollTo(0, 0);
}

function getSeason() {
    const month = new Date().getMonth() + 1;
    var season = '';
    switch (month) {
        case 10:
        case 11:
        case 12:
        case 1:
        case 2:
        case 3:
        case 4:
            season = 'winter';
            break;
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            season = 'summer';
            break;
    }

    return season;
}

import Vue from 'vue';
import Vuex from 'vuex';
import BootstrapVue from 'bootstrap-vue';
import VueRouter from 'vue-router';
import MomentVue from 'vue-moment';
import VueSplide from '@splidejs/vue-splide';
import VideoBackground from 'vue-responsive-video-background-player';
import x5GMaps from 'x5-gmaps';


import Home from './components/Home.vue';
import Menu from './components/Menu.vue';
import Booking from './components/Booking.vue';
import Footer from './components/Footer.vue';
import Welcome from './components/Welcome.vue';

import i18n from './i18n'


Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(MomentVue);
Vue.use(VueSplide);

// Option 1: Just your key
// Vue.use(x5GMaps, 'AIzaSyBvJ9i0rbHxQ6osR0vQszMb-E2hUd4PAmI');
// Option 2: With libraries
Vue.use(x5GMaps, { key: 'AIzaSyBvJ9i0rbHxQ6osR0vQszMb-E2hUd4PAmI', libraries: ['places'] });

Vue.component('video-background', VideoBackground);
Vue.component('alp-home', Home);
Vue.component('alp-menu', Menu);
Vue.component('alp-booking', Booking);
Vue.component('alp-footer', Footer);
Vue.component('alp-welcome', Welcome);

const routes = [
    { path: '/', component: Booking, name: 'home' },
    { path: '/booking/:hotel/:apartment', component: Booking, name: 'booking' },
    { path: '/about', component: Home, name: 'about',}
];

const router = new VueRouter({
    mode: 'history',
    history: true,
    routes: routes
});

const greMixin = {
    methods: {
      greToken() {
        return window.greToken;
      },
      grecaptcha() {
          return window.grecaptcha;
      }
    }
  }

new Vue({
    i18n,
    el: '#app',
    router: router,
    mixins: [greMixin],
    data() {
        return {
            season: "",
            lang: ""
        };
    },
    methods: {
        swSeason() {
            this.season = this.season == 'winter' ? 'summer' : 'winter';
            localStorage.setItem('season', this.season);
        },
        swLang() {
            console.log('change');
            this.lang = this.lang == 'EN' ? 'DE' : 'EN';
            localStorage.setItem('lang', this.lang);
            this.$root.$i18n.locale = this.lang == 'EN' ? 'en-US' : 'de-CH';
            //workaround
            top.location.reload();
        }
    },
    created() {
        const season = localStorage.getItem('season');

        if (season) {
            this.season = season;
        } else {
            this.season = getSeason();
        }

        const lang = localStorage.getItem('lang');
        if (lang) {
            this.lang = lang;
        } else {
            this.lang = "DE";
        }
    }
});
