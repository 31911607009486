<template>
    <div>
        <div class="container-fluid p-0 alp-home-contact mt-5" id="location-contact-us">
            <b-container>
                <b-row class="py-4">
                    <b-col>
                        <b-row class="h-100" align-v="end">
                            <b-col cols="12">
                                <p
                                    class="font-weight-bold text-white alp-bodoni d-none d-md-block"
                                    style="font-size: 2.5rem"
                                >
                                    {{this.$t("Contact us")}}
                                </p>
                                <p
                                    class="font-weight-bold text-white alp-bodoni d-md-none mt-4"
                                    style="font-size: 1.5rem"
                                >
                                    {{this.$t("Contact us")}}
                                </p>
                                <p class="text-white" v-html="this.$t('openingHours')">
                                    
                                </p>
                            </b-col>
                            <b-col class="alp-home-contact-stamp mt-3 mb-5 mb-md-0">
                                
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="12" md="5">
                        <div class="p-4 bg-white rounded">
                            <!-- Add the visible email link -->
                            <a href="mailto:restaurant@spannort.ch" class="d-block mb-3">restaurant@spannort.ch</a>

                            <!-- Make the button open the email -->
                            <b-button class="py-3" variant="alp-gold w-100" href="mailto:restaurant@spannort.ch">
                                {{this.$t('Send us a message')}}
                            </b-button>
                        </div>
                        <!-- <form class="p-4 bg-white rounded" action="https://alpapartments.ch/mail/send/" method="POST" ref="formRef">
                            <b-form-input
                                name="token"
                                ref="tokenRef"
                                class="hidden d-none d-md-none"
                                type="hidden"
                                >
                                </b-form-input>
                            <b-form-input
                                style="font-size: 0.8125rem"
                                size="lg"
                                class="mb-4"
                                :placeholder="this.$t('Full name')"
                                v-model="fullName"
                            ></b-form-input>
                            <b-form-input
                                style="font-size: 0.8125rem"
                                size="lg"
                                class="mb-4"
                                :placeholder="this.$t('Company')"
                                v-model="company"
                            ></b-form-input>
                            <b-form-input
                                style="font-size: 0.8125rem"
                                size="lg"
                                class="mb-4"
                                :placeholder="this.$t('Email')"
                                v-model="email"
                            ></b-form-input>
                            <b-form-textarea
                                style="font-size: 0.8125rem"
                                size="lg"
                                class="mb-4"
                                :placeholder="this.$t('Enter something...')"
                                rows="3"
                                v-model="text"
                            ></b-form-textarea>
                            <p v-if="this.formResponseSuccess" class="text-success">{{this.$t("contactFormResponseSuccess")}} </p>
                            <p v-if="this.formResponseError" class="text-error">{{this.$t("contactFormResponseError")}} </p>
                            <b-button class="py-3" variant="alp-gold w-100" @click="formSubmit()">{{this.$t('Send us a message')}}</b-button>
                        </form> -->
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div class="container-fluid alp-home-footer">
            <div class="container pt-2 pt-md-5">
                <b-row class="text-white py-4">
                    <b-col class="mb-4 mb-md-0" sm="12" md="auto">
                        <img class="d-none d-md-block" src="/img/logo/mobile/logo.png" />
                        <img class="d-md-none" src="/img/logo/mobile/logo.png" />
                    </b-col>
                    
                    <b-col sm="12" md="auto">
                        SPANNORT RESTAURANT
                        <p class="text-alp-muted mt-2" style="font-size: 0.75rem">
                            Dorfstrasse 28<br />
                            CH-6390 Engelberg<br />
                            www.spannort.ch<br />
                            restaurant@spannort.ch<br />
                            +41(0)41 500 11 33
                        </p>
                    </b-col>
                   
                    <b-col class="text-md-right text-left mt-5 mt-md-0">
                        <a href="https://www.facebook.com/Spannort/" target="_blank" class='text-white'><i style="font-size: 1.2rem" class="mr-3 ml-3 fab fa-facebook"></i></a>
                        <a href="https://www.instagram.com/restaurantspannort/" target="_blank" class='text-white'><i style="font-size: 1.2rem" class="mr-3 ml-3 fab fa-instagram"></i></a>
                        <a href="https://www.engelberg.ch/en/webcams/" target="_blank" class='text-white'><i style="font-size: 1.2rem" class="mr-3 ml-3 fas fa-video"></i></a>
                        <a href="https://www.engelberg.ch/en/open-trails/" target="_blank" class='text-white'><i style="font-size: 1.2rem" class="mr-3 ml-3 fas fa-tram"></i></a>
                        <a href="https://www.engelberg.ch/en/weather/" target="_blank" class='text-white'><i style="font-size: 1.2rem" class="mr-3 ml-3 fas fa-thermometer-half"></i></a>
                    </b-col>
                </b-row>
                <b-row class="py-4">
                    <b-col class="text-left text-md-center text-alp-muted">
                        <hr style="background: rgba(112, 112, 112, 0.2)" />
                        All rights reserved to Spannort Restaurant 2021
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
           email: '',
           text: '',
           fullName: '',
           company: '',  
           formResponseSuccess: false,
           formResponseError: false,
        };
    },
    computed: {},
    methods: {
        goToDefaultAlpApartment() {
            this.$router.push("/booking/alp-apartments/vogel-apartment");
            window.scrollTo({
                left: 0,
                top: 0,
            });
        },
        goToDefaultSpannortApartment() {
            this.$router.push("/booking/spannort-gasthaus-and-restaurant/adventurer-small");
            window.scrollTo({
                left: 0,
                top: 0,
            });
        },
        goToDefaultGrandApartment() {
            this.$router.push("/booking/grand-hotel-and-bar/adventurer-small");
            window.scrollTo({
                left: 0,
                top: 0,
            });
        },
        openEmail() {
            window.location.href = 'mailto:restaurant@spannort.ch';
        },
        formSubmit() {
            //this.$refs.tokenRef.$el.value = this.$root.greToken();
            // configure a request
           
            // prepare form data
            

        this.$root.grecaptcha().enterprise.execute('6LfTLKkdAAAAAHAxG2gxt0Vh71f6lIdENSy1Cdjd', {action: 'login'}).then((token) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://alpapartments.ch/mail/send/');

            let data = JSON.stringify({
                fullName: this.fullName,
                company: this.company,
                email: this.email,
                text: this.text,
                token: token
            });

            // set headers
           xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

            // send request
            xhr.send(data);

            // listen for `load` event
            this.formResponseError = false;
            this.formResponseSuccess = false;
            xhr.onload = () => {
                let res = JSON.parse(xhr.responseText);
                if (res.success) {
                    this.formResponseSuccess = true;
                } else {
                    this.formResponseError = true;
                }
            }
        });
        }
    },
};
</script>

